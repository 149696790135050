import { format } from 'date-fns'

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({ slug }) {
  return (slug || {}).current
}

export function getBlogUrl (publishedAt, slug) {
  return `/blog/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function buildImageObj (source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id }
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function formatUrl (link, title) {
  if(link?.length){
    if(link[0]?.page){
      if(link[0]?.page?.slug?.current){
        return {url: `/${link[0].page.slug.current}`, title: link[0]?.title || title, external: false}
      }
      else{
        return {url: `/`, title: link[0]?.title || title, external: false}
      }
    }
    if(link[0]?.link){
      return {url: link[0].link, title: link[0]?.title || title, external: link?.external}
    }
  }
  if (link?.page?.slug?.current) {
    return {url: `/${link.page.slug.current}`, title: link.title, external: false}
  }
  if (link?.link && typeof(link.link) === 'string'){
    return {url: link.link, title: link.title, external: link?.external}
  }
  return {url: null, title: null, external: false}
}

export function getMainMenu (menu) {
    if(menu?.length){
        return menu?.map((item) => formatUrl([item], item.title))
    }
    return null
}